module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/logo/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f4a91e7ea01cf929ee0e99c3fe9d2716"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/logo/logo_512x512.png","icons":[{"src":"./src/images/logo/logo.png","sizes":"16x16","type":"image/png"},{"src":"./src/images/logo/logo.ico","sizes":"32x32","type":"image/vnd.microsoft.icon"},{"src":"./src/images/logo/logo_192x192.png","sizes":"192x192","type":"image/png"},{"src":"./src/images/logo/logo_512x512.png","sizes":"512x512","type":"image/png"},{"src":"./src/images/logo/logo_512x512.png","sizes":"512x512","type":"image/png"},{"src":"./src/images/logo/maskable_logo.png","sizes":"128x128","type":"image/png","purpose":"maskable"}],"name":"RedaOS","short_name":"RedaOS","description":"Philadelphia-based fullstack developer and engineer Reda Elmountassir offers bespoke software, graphic design, and full-stack development. Explore this OS-like portfolio experience!","start_url":"/","background_color":"#1f0728","theme_color":"#f9c80e","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3a30ee077babbe741851f572c03efda7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
